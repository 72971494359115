import { createAction } from 'typesafe-actions';

export const ADD_FORM = 'ADD_FORM';
export const addForm = createAction(ADD_FORM)();

export const CHANGE_FORM_VALUE = 'CHANGE_FORM_VALUE';
export const changeFormValue = createAction(CHANGE_FORM_VALUE)();

export const REMOVE_OFFER_FORM = 'REMOVE_OFFER_FORM';
export const removeOfferForm = createAction(REMOVE_OFFER_FORM)();

export const APPLY_FORM = 'APPLY_FORM';
export const applyForm = createAction(APPLY_FORM)();

export const OFFER_FORM_END = 'OFFER_FORM_END';
export const offerFormEnd = createAction(OFFER_FORM_END)();

export const ERROR_APPLY_PRICE_EXCEED = 'ERROR_APPLY_PRICE_EXCEED';
export const errorPriceExceed = createAction(ERROR_APPLY_PRICE_EXCEED)();

export const ERROR_APPLY_PRICE = 'ERROR_APPLY_PRICE';
export const errorPriceInput = createAction(ERROR_APPLY_PRICE)();

export const ERROR_APPLY_PRICE_UNIT = 'ERROR_APPLY_PRICE_UNIT';
export const errorPriceUnit = createAction(ERROR_APPLY_PRICE_UNIT)();

export const ERROR_APPLY_PRICE_CLEAR = 'ERROR_APPLY_PRICE_CLEAR';
export const errorPriceClear = createAction(ERROR_APPLY_PRICE_CLEAR)();

export const ERROR_APPLY_INFO = 'ERROR_APPLY_INFO';
export const errorInfoInput = createAction(ERROR_APPLY_INFO)();

export const ERROR_APPLY_NAME_FORMAT = 'ERROR_APPLY_NAME_FORMAT';
export const errorNameFormat = createAction(ERROR_APPLY_NAME_FORMAT)();

export const ERROR_APPLY_PHONE_FORMAT = 'ERROR_APPLY_PHONE_FORMAT';
export const errorPhoneFormat = createAction(ERROR_APPLY_PHONE_FORMAT)();

// P: Philing
export const INIT_PHILING_QUESTION = 'INIT_PHILING_QUESTION';
export const initQuestion = createAction(INIT_PHILING_QUESTION)();

export const SUBMIT_PHILING_QUESTION = 'SUBMIT_PHILING_QUESTION';
export const submitQuestion = createAction(SUBMIT_PHILING_QUESTION)();

export const SET_PHILING_QUESTION = 'SET_PHILING_QUESTION';
export const setQuestion = createAction(SET_PHILING_QUESTION)();

// 유저 답변 내용 저장
export const SET_PHILING_ANSWER = 'SET_PHILING_ANSWER';
export const setAnswer = createAction(SET_PHILING_ANSWER)();

// 유저 문진페이퍼 ID 저장 : paperId
export const SET_PHILING_PAPER = 'SET_PHILING_PAPER';
export const setPaper = createAction(SET_PHILING_PAPER)();

// 유저 문진 정보 저장 : rrn, name, phonenum
export const SET_PHILING_PATIENT_INFO = 'SET_PHILING_PATIENT_INFO';
export const setPatientInfo = createAction(SET_PHILING_PATIENT_INFO)();

// 유저 결제 정보 저장 : id, cardName
export const SET_PHILING_PAYMENT = 'SET_PHILING_PAYMENT';
export const setPayment = createAction(SET_PHILING_PAYMENT)();

// 이전 기록에서 퀴즈 답안 복구, localStorage에 있는 데이터 복구용
export const RESTORE_PHILING_QUESTION = 'RESTORE_PHILING_QUESTION';
export const restoreQuestion = createAction(RESTORE_PHILING_QUESTION)();

// 기록 제거, localStorage 도 제거
export const RESET_PAPER = 'RESET_PAPER';
export const resetPaper = createAction(RESET_PAPER)();

// PAPER v2
export const NEXT_PAPER_PAGE = 'NEXT_PAPER_PAGE';
export const nextPaperPage = createAction(NEXT_PAPER_PAGE)();

export const AUTO_SKIP_NEXT_PAPER_PAGE = 'AUTO_SKIP_NEXT_PAPER_PAGE';
export const autoSkipNextPaperPage = createAction(AUTO_SKIP_NEXT_PAPER_PAGE)();

export const PUSH_PAPER_PROCESS = 'PUSH_PAPER_PROCESS';
export const pushPaperProcess = createAction(PUSH_PAPER_PROCESS)();

export const SET_PAPER_RESPONSE = 'SET_PAPER_RESPONSE';
export const setPaperResponse = createAction(SET_PAPER_RESPONSE)();

export const ROUTER_CHANGE = 'ROUTER_CHANGE';
export const routerChange = createAction(ROUTER_CHANGE)();

export const EDIT_PAPER_PROCESS = 'EDIT_PAPER_PROCESS';
export const editPaperProcess = createAction(EDIT_PAPER_PROCESS)();

export const DELETE_PAPER_PROCESS = 'DELETE_PAPER_PROCESS';
export const delPaperProcess = createAction(DELETE_PAPER_PROCESS)();

export const SUBMIT_QUESTION_ANSWER = 'SUBMIT_QUESTION_ANSWER';
export const submitQuestionAnswer = createAction(SUBMIT_QUESTION_ANSWER)();

export const SUBMIT_QUESTION_ANSWER_SUCCESS = 'SUBMIT_QUESTION_ANSWER_SUCCESS';

export const SUBMIT_QUESTION_ANSWER_ERROR = 'SUBMIT_QUESTION_ANSWER_ERROR';

export const SUBMIT_QUESTION_PAPER = 'SUBMIT_QUESTION_PAPER';
export const submitQuestionPaper = createAction(SUBMIT_QUESTION_PAPER)();

export const SUBMIT_QUESTION_PAPER_SUCCESS = 'SUBMIT_QUESTION_PAPER_SUCCESS';

export const SUBMIT_QUESTION_PAPER_ERROR = 'SUBMIT_QUESTION_PAPER_ERROR';

export const SUBMIT_QUESTION_PAPER_FINAL = 'SUBMIT_QUESTION_PAPER_FINAL';

export const CANCEL_PENDING = 'CANCEL_PENDING';
export const cancelPending = createAction(CANCEL_PENDING)();

export const SET_POPUP = 'SET_POPUP';
export const setPopup = createAction(SET_POPUP)();

export const EDIT_QUESTION_PAPER = 'EDIT_QUESTION_PAPER';
export const editQuestionPaper = createAction(EDIT_QUESTION_PAPER)();
