import { useState, useEffect } from 'react';
import { RecoilRoot } from 'recoil';
import { ConnectedRouter } from 'connected-next-router';
import { ApolloProvider } from '@apollo/client';
import TagManager from 'react-gtm-module';
import Router from 'next/router';
import { AnimatePresence } from 'framer-motion';

import '../../public/assets/scss/index.scss';
import { useApollo } from 'apolloClient';
import { storeWrapper } from '../store';
import PageLoader from 'components/atoms/PageLoader';
import LayoutLeftTemplate from 'components/templates/LayoutLeftTemplate';
import LayoutLeftTemplateKakao from 'components/templates/LayoutLeftTemplateKakao';

function App({ Component, pageProps }) {
  const [pageLoading, setPageLoading] = useState(false);
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    if (process.env.NEXT_PUBLIC_NODE_ENV === 'production') {
      const tagManagerArgs = {
        gtmId: 'GTM-5ZLVSHV',
      };
      const tagManagerArgs2 = {
        gtmId: 'GTM-KMB5LZW',
      };

      TagManager.initialize(tagManagerArgs);
      TagManager.initialize(tagManagerArgs2);
    }

    Router.beforePopState(({ as, options, url }) => {
      // 문진중이 아닐때, 뒤로가기로 문진 진입 차단
      if (url.match(/paper\?page=/gi)) {
        if (!Router.router.asPath.match(/paper\?page=/gi)) {
          window.history.pushState(`/`, '');
          Router.push(`/philing`);
        }
        return false;
      }
      return true;
    });

    return () => {
      if (window) {
        window.onbeforeunload = null;
      }
      Router.beforePopState(() => {
        return true;
      });
    };
  }, []);

  Router.onRouteChangeStart = () => {
    setPageLoading(true);
  };

  Router.onRouteChangeComplete = () => {
    setPageLoading(false);
  };

  Router.onRouteChangeError = () => {
    setPageLoading(false);
  };

  if (pageProps.error) {
    return (
      <div>
        <h1>{pageProps.error.statusCode}</h1>
        <h1>{pageProps.error.message}</h1>
      </div>
    );
  }

  // 외부 form 단독
  if (pageProps.isolate) {
    return (
      <ConnectedRouter>
        <ApolloProvider client={apolloClient}>
          <RecoilRoot>
            <AnimatePresence
              exitBeforeEnter
              initial={false}
              // onExitComplete={() => window.scrollTo(0, 0)}
            >
              <Component {...pageProps} />
            </AnimatePresence>
          </RecoilRoot>
        </ApolloProvider>
      </ConnectedRouter>
    );
  }

  if (pageProps.kakaoPt) {
    return (
      <ConnectedRouter>
        <ApolloProvider client={apolloClient}>
          <RecoilRoot>
            <main id="main">
              <section id="left">
                <LayoutLeftTemplateKakao />
              </section>
              <section id="right">
                <AnimatePresence
                  exitBeforeEnter
                  initial={false}
                  // onExitComplete={() => window.scrollTo(0, 0)}
                >
                  <Component {...pageProps} />
                </AnimatePresence>
              </section>
            </main>
            {pageLoading && <PageLoader />}
          </RecoilRoot>
        </ApolloProvider>
      </ConnectedRouter>
    );
  }

  return (
    <ConnectedRouter>
      <ApolloProvider client={apolloClient}>
        <RecoilRoot>
          <main id="main">
            <section id="left">
              <LayoutLeftTemplate />
            </section>
            <section id="right">
              <AnimatePresence
                exitBeforeEnter
                initial={false}
                // onExitComplete={() => window.scrollTo(0, 0)}
              >
                <Component {...pageProps} />
              </AnimatePresence>
            </section>
          </main>
          {pageLoading && <PageLoader />}
        </RecoilRoot>
      </ApolloProvider>
    </ConnectedRouter>
  );
}

export default storeWrapper.withRedux(App);
