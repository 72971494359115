export const PC_MENU = [
  [
    {
      label: '얼굴',
      link: '/philing/1',
    },
    {
      label: '몸매',
      link: '/philing/2',
    },
    {
      label: '피부',
      link: '/philing/2',
    },
  ],
  [
    // {
    //   label: '성기능',
    //   link: '/philing/3',
    // },
    {
      label: '치아',
      link: '/philing/4',
    },
    {
      label: '탈모',
      link: '/philing/5',
    },
  ],
];
