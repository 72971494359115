import createReducer from 'utils/immerWrapper';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';

import {
  ADD_FORM,
  CHANGE_FORM_VALUE,
  APPLY_FORM,
  OFFER_FORM_END,
  REMOVE_OFFER_FORM,
  ERROR_APPLY_PRICE_UNIT,
  ERROR_APPLY_PRICE_EXCEED,
  ERROR_APPLY_PRICE_CLEAR,
  ERROR_APPLY_NAME_FORMAT,
  ERROR_APPLY_PHONE_FORMAT,
  ERROR_APPLY_PRICE,
  ERROR_APPLY_INFO,
  INIT_PHILING_QUESTION,
  SUBMIT_PHILING_QUESTION,
  SET_PHILING_QUESTION,
  SET_PHILING_ANSWER,
  SET_PHILING_PAPER,
  SET_PHILING_PATIENT_INFO,
  RESTORE_PHILING_QUESTION,
  RESET_PAPER,
  SET_PHILING_PAYMENT,
  PUSH_PAPER_PROCESS,
  SET_PAPER_RESPONSE,
  DELETE_PAPER_PROCESS,
  EDIT_PAPER_PROCESS,
  SUBMIT_QUESTION_ANSWER,
  SUBMIT_QUESTION_ANSWER_SUCCESS,
  SUBMIT_QUESTION_ANSWER_ERROR,
  SUBMIT_QUESTION_PAPER,
  SUBMIT_QUESTION_PAPER_SUCCESS,
  SUBMIT_QUESTION_PAPER_ERROR,
  SUBMIT_QUESTION_PAPER_FINAL,
  NEXT_PAPER_PAGE,
  SET_POPUP,
  EDIT_QUESTION_PAPER,
} from './form.actions';

const PAPER_PAGES = {
  'check-if-possible': {
    title: '생년월일과 성별을 입력해주세요.',
    description: null,
    component: 'CheckIfPossible',
    nextPage: 'intro-question',
    autoSkip: false,
    header: true,
    progressBar: false,
    isInit: false, // 라우팅 타고 처음 들어온 페이지만 init
    isQuestion: false,
  },
  'intro-question': {
    title: '',
    description: '',
    component: 'IntroQuestion',
    nextPage: 'question-1',
    autoSkip: 3000,
    header: false,
    progressBar: false,
    isInit: false,
    isQuestion: false,
  },
  question: {
    title: null,
    description: null,
    component: 'Question',
    nextPage: null,
    autoSkip: 3000,
    header: true,
    progressBar: true,
    isInit: false,
    isQuestion: true,
  },
  'almost-done': {
    title: '',
    description: '',
    component: 'AlmostDone',
    nextPage: 'personal-information',
    autoSkip: 3000,
    header: false,
    progressBar: false,
    isInit: false,
    isQuestion: false,
  },
  'personal-information': {
    title: '진료 접수를 위해 이름과 주민등록번호 뒷자리를 입력해주세요.',
    description: '만 18세 이상의 성인인 환자 본인만 진료가 가능해요.',
    component: 'PersonalInformation',
    nextPage: 'phone-number-verification',
    autoSkip: 3000,
    header: true,
    progressBar: true,
    isInit: false,
    isQuestion: false,
  },
  'phone-number-verification': {
    title: '비대면 전화 진료를 위해 휴대폰 번호를 입력해주세요.',
    description: '',
    component: 'PhoneNumberVerification',
    nextPage: 'payment-method',
    autoSkip: 3000,
    header: true,
    progressBar: true,
    isInit: false,
    isQuestion: false,
  },
  'payment-method': {
    title: '진료비와 약값을 결제할 카드를 선택해주세요.',
    description: '진료비는 선택한 카드로 자동 결제되고, 약값은 조제 확인 후 바로 결제할 수 있어요.',
    component: 'PaymentMethod',
    nextPage: 'paper-done',
    autoSkip: 3000,
    header: true,
    progressBar: true,
    isInit: false,
    isQuestion: false,
  },
  'paper-done': {
    title: '모든 정보를 틀림 없이 사실대로 입력했나요?',
    description: '입력하신 모든 정보는 정확한 진료와 안전한 처방을 위해 이용됩니다.',
    component: 'PaperDone',
    nextPage: null,
    autoSkip: 3000,
    header: true,
    progressBar: true,
    isInit: false,
    isQuestion: false,
    redirect: '/',
  },
};

const initialFormsState = {
  POPUP_PAGE: {
    login: false,
  },
  PAPER_FORM: {
    page: null,
    paper: null,
    paperProcess: [],
    paperResponse: {},
    patientInfo: {
      paperId: null,
      rrn: null,
      name: null,
      phonenum: null,
      birthDate: null,
      gender: null,
      department: null,
      payment: {
        id: null,
        cardName: null,
      },
    },
  },
};

const formReducer = createReducer(initialFormsState, {
  [ADD_FORM]: (draft, action) => {
    const { key, initialState } = action.payload;
    if (!draft[key]) {
      draft[key] = initialState;
    }
  },

  [CHANGE_FORM_VALUE]: (draft, action) => {
    const { key, name, value } = action.payload;
    // invariant(!!draft[key], `No such form : '${key}'`);
    // invariant(
    //   Object.keys(draft[key]).includes(name),
    //   `'${key}' form does not initially contain '${name}' key! Have you forgot to add it ?`
    // );
    draft[key][name] = value;
  },

  [REMOVE_OFFER_FORM]: (draft, action) => {
    const { key } = action.payload;
    delete draft[key];
  },

  [APPLY_FORM]: (state) => ({ ...state, isFetching: true, isFetchingExcel: true }),

  [OFFER_FORM_END]: (draft, action) => {
    const { key } = action.payload;
    draft[key] = initialFormsState;
  },
  [ERROR_APPLY_PRICE_CLEAR]: (draft, action) => {
    const { key } = action.payload;
    for (const [k] of Object.entries(draft[key].error)) {
      draft[key].error[k] = false;
    }
  },
  [ERROR_APPLY_PRICE_UNIT]: (draft, action) => {
    const { key } = action.payload;
    draft[key].error.unit = true;
  },
  [ERROR_APPLY_PRICE_EXCEED]: (draft, action) => {
    const { key } = action.payload;
    draft[key].error.exceed = true;
  },
  [ERROR_APPLY_PRICE]: (draft, action) => {
    const { key, value } = action.payload;
    draft[key].error = { ...value };
  },
  [ERROR_APPLY_INFO]: (draft, action) => {
    const { key, value } = action.payload;
    draft[key].error = {
      ...draft[key].error,
      ...value,
    };
  },
  [ERROR_APPLY_NAME_FORMAT]: (draft, action) => {
    const { key } = action.payload;
    draft[key].error.name = true;
  },
  [ERROR_APPLY_PHONE_FORMAT]: (draft, action) => {
    const { key } = action.payload;
    draft[key].error.phone = true;
  },
  [INIT_PHILING_QUESTION]: (draft, action) => {
    const { key, value } = action.payload;
    draft[key] = {
      ...draft[key],
      currentStep: value.order,
      isInit: true,
    };
  },
  [SUBMIT_PHILING_QUESTION]: (draft, action) => {
    const { key } = action.payload;
    draft[key].error.phone = true;
  },
  [SET_PHILING_QUESTION]: (draft, action) => {
    const { key, value } = action.payload;
    draft[key].currentStep = value.order;
  },
  [SET_PHILING_ANSWER]: (draft, action) => {
    const { key, answer } = action.payload;
    draft[key].answer = [
      ...draft[key].answer.filter((d) => d.questionId !== answer.questionId),
      answer,
    ];
  },
  [SET_PHILING_PAPER]: (draft, action) => {
    const { key, paperId } = action.payload;
    draft[key].patientInfo = {
      ...draft[key].patientInfo,
      paperId,
    };
  },
  [SET_PHILING_PATIENT_INFO]: (draft, action) => {
    const { key, info } = action.payload;

    draft[key].patientInfo = {
      ...draft[key].patientInfo,
      ...info, //rrn, name, phonenum
    };
  },
  [SET_PHILING_PAYMENT]: (draft, action) => {
    const { key, payment } = action.payload;
    draft[key].payment = {
      ...draft[key].payment,
      ...payment, // id, cardName
    };
  },
  [RESTORE_PHILING_QUESTION]: (draft, action) => {
    const { key, value } = action.payload;
    draft[key] = value;
  },
  [RESET_PAPER]: (draft) => {
    draft.PAPER_FORM = initialFormsState.PAPER_FORM;
  },
  [NEXT_PAPER_PAGE]: (draft, action) => {
    const { key, page } = action.payload;
    const existProcess = draft[key].paperProcess;
    const isFirstPage = !draft[key].paperProcess.length;
    const newPaper = {
      ...PAPER_PAGES[/question-/g.test(page) ? 'question' : page],
      isInit: isFirstPage,
    };

    draft[key] = {
      ...draft[key],
      page: page,
      paper: newPaper,
      paperProcess: [
        ...existProcess,
        {
          [page]: newPaper,
        },
      ],
    };
  },
  [PUSH_PAPER_PROCESS]: (draft, action) => {
    const { key, value } = action.payload;
    const existProcess = draft[key].paperProcess;

    draft[key].paperProcess = [...existProcess, value];
  },
  [DELETE_PAPER_PROCESS]: (draft, action) => {
    const {
      key,
      value: { page },
    } = action.payload;
    const existProcess = draft[key].paperProcess;
    const isExistPage = find(existProcess, page);

    if (isExistPage) {
      const dropIndex = findIndex(existProcess, isExistPage);
      const newProcess = existProcess.slice(0, dropIndex + 1);

      draft[key] = {
        ...draft[key],
        page: page,
        paper: Object.values(newProcess.slice(-1).pop())[0],
        paperProcess: newProcess,
      };
    }
  },
  [EDIT_PAPER_PROCESS]: (draft, action) => {
    // 현재 진행중인 프로세스만 수정 가능
    const { key, value } = action.payload;
    // value = { a: {...} }
    // Object.keys(value);
    // ['a']
    const [findKey] = Object.keys(value);

    const keyIndex = draft[key].paperProcess.findIndex((obj) => {
      const [k] = Object.keys(obj);
      return k == findKey;
    });

    draft[key].paperProcess[keyIndex] = {
      [findKey]: {
        ...draft[key].paperProcess[keyIndex][findKey],
        ...value[findKey],
      },
    };
  },
  [SET_PAPER_RESPONSE]: (draft, action) => {
    const { key, value } = action.payload;
    const existResponse = draft[key].paperResponse;
    const existPatientInfo = draft[key].patientInfo;

    draft[key] = {
      ...draft[key],
      paperResponse: { ...existResponse, [value.page]: value.answer.response },
      patientInfo: { ...existPatientInfo, ...value.answer.info },
    };
  },
  [SUBMIT_QUESTION_ANSWER]: () => {
    // do someThing
  },
  [SUBMIT_QUESTION_ANSWER_SUCCESS]: (draft, action) => {
    // 정답 제출이 완료 되면
    // paperProcess에 현재 진행 단계를 수정하고 (nextPage: null -> nextPage: `question-${nextPage.order}`)
    // paperResponse에 답변을 저장한다 (question-1: { ... })
    const {
      key,
      answer,
      page,
      submitQuestionAnswer: { nextQuestion },
      nullNextPage,
    } = action.payload;

    const keyIndex = draft[key].paperProcess.findIndex((obj) => {
      const [k] = Object.keys(obj);
      return k == page;
    });

    const existResponse = draft[key].paperResponse;
    const existPatientInfo = draft[key].patientInfo;
    const modPaperProcess = draft[key].paperProcess.slice();
    const nextQuestionOrder = nextQuestion?.order;
    const nextPage = nextQuestionOrder ? `question-${nextQuestionOrder}` : nullNextPage;

    modPaperProcess[keyIndex] = {
      [page]: {
        ...modPaperProcess[keyIndex][page],
        nextPage: nextPage,
      },
    };

    draft[key] = {
      ...draft[key],
      paperResponse: { ...existResponse, [page]: answer },
      patientInfo: { ...existPatientInfo, ...answer.info },
      paperProcess: modPaperProcess,
    };
  },
  [SUBMIT_QUESTION_ANSWER_ERROR]: () => {
    // do someThing
  },
  [SUBMIT_QUESTION_PAPER]: () => {
    // do someThing
  },
  [SUBMIT_QUESTION_PAPER_SUCCESS]: (draft, action) => {
    // 정답 제출이 완료 되면
    // paperProcess에 현재 진행 단계를 수정하고 (nextPage: null -> nextPage: `question-${nextPage.order}`)
    // paperResponse에 답변을 저장한다 (question-1: { ... })
    const {
      key,
      createQuestionPaper: { id: paperId },
    } = action.payload;

    const existPatientInfo = draft[key].patientInfo;

    draft[key] = {
      ...draft[key],
      patientInfo: { ...existPatientInfo, paperId },
    };
  },
  [SUBMIT_QUESTION_PAPER_FINAL]: (draft) => {
    draft.PAPER_FORM = initialFormsState.PAPER_FORM;
  },
  [SUBMIT_QUESTION_PAPER_ERROR]: (draft, action) => {
    console.error(action.payload);
    // do someThing
  },
  [SET_POPUP]: (draft, action) => {
    const { page, value } = action.payload;
    draft['POPUP_PAGE'][page] = value;
  },
  [EDIT_QUESTION_PAPER]: (draft, action) => {
    const { key, question } = action.payload;
    draft[key].paper = {
      ...draft[key].paper,
      ...question,
    };
  },
});

export default formReducer;
